import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';

const AFKGuidesCharms: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Charms - Season 3</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_arti_s.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Charms - Season 3</h1>
          <h2>List of Charms available in the Season3 of AFK Journey.</h2>
          <p>
            Last updated: <strong>03/04/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Charms - Season 3" />
        <p>
          Please keep in mind that the bonuses listed below{' '}
          <strong>show the values of the buffs at Mythic</strong> - so the
          highest rarity of them. At lower rarities, the value of the buffs and
          the buff itself will be weaker.
        </p>
        <div className="artifacts with-charms">
          <div className="column">
            <h5>Charm #1</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Increases damage dealt by <b>50%</b> to enemies with less than{' '}
                <b>50%</b> HP. Gains a <b>300%</b> shield for <b>8s</b> when
                assisting or defeating a non-summoned enemy unit, While this
                shield is active, increases ATK by <b>20%</b>.
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="cyran"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="seth"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #2</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                During the battle, increases ATK by <b>10%</b> each time the
                caster deals a total of 800% damage with non-normal attacks, up
                to a max increase of <b>50%</b> ATK. This effect lasts until the
                end of the battle. When reaching its max stack, gains <b>30</b>{' '}
                extra Haste until the end of the battle.
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="mirael"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="carolina"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="atalanta"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #3</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Unleashes a shockwave every <b>5s</b>, dealing <b>200%</b>{' '}
                damage to surrounding enemies within <b>2</b> tiles and reducing
                their Phys DEF and Magic DEF by <b>30%</b> for <b>5s</b>. This
                effect can't stack. Restores the caster's HP equal to <b>30%</b>{' '}
                of the damage dealt by this skill.
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="granny-dahnie"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="thoran"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="lumont"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="valka"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="sinbad"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="sonja"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #4</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Gains a <b>220%</b> shield for <b>5s</b> each time the caster
                deals a total of <b>800%</b> damage during battle, This effect
                can be triggered up to <b>10</b> times per battle. Also grants a{' '}
                <b>450%</b> shield for <b>15s</b> at the start of a battle,
                making the caster Unaffected as long as this shield remains
                active.
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="kruger"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="salazer"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="walker"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="soren"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #5</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Restores <b>40</b> Energy per second during battle. Once a total
                of <b>900</b> Energy is restored through this effect, increases
                the caster's Ultimate damage by <b>50%</b> for the rest of the
                battle.
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="talene"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="alsa"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="satrana"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="cassadee"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="parisa"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #6</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Every <b>10s</b> in battle, increases the caster's ATK by{' '}
                <b>8%</b>, and their Phys & Magic DEF by <b>16%</b>. The effect
                lasts until the end of the battle. After <b>60s</b> have passed
                in a battle, the caster's attack deals extra damage equal to{' '}
                <b>40%</b> of the target's lost HP, up to the caster's{' '}
                <b>320%</b> attack. The bonus damage can be triggered once every{' '}
                <b>6s</b> per enemy.
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="harak"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="temesia"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="tasi"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="lily-may"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="igor"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #7</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Grants a <b>50%</b> shield and <b>45</b> Energy on Hit for{' '}
                <b>8s</b> to the ally placed <b>1</b> tile in front of the
                caster when a battle starts. This ally's first Ultimate cast can
                also stun all enemies for <b>3s</b>.
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="koko"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="damian"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="smokey-and-meerky"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #8</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                When a battle starts, gains <b>40%</b> ATK and <b>40</b> Haste.
                These buffs last until the end of the battle but decay by{' '}
                <b>2%</b> ATK and <b>2</b> Haste per second until they reach
                zero. Assisting or defeating a non-summoned enemy unit for the
                first time will stop the decay and restores the caster's HP
                equal to <b>150%</b> of the total damage dealt to this target.
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="vala"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="lorsan"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="silvina"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="kafra"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="nara"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #9</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                When dealing damage to a target, causes splash damage equal to{' '}
                <b>40%</b> of the base damage to their adjacent enemies. Splash
                damage won't trigger the effect of this skill. When casting
                Ultimate, deals damage equal to <b>12%</b> of current HP to all
                enemies, up to <b>280%</b>.
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="eironn"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="shakir"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="arden"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #10</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                When a battle starts, grants the ally placed <b>1</b> tile
                behind the caster the ability to convert <b>40%</b> of their
                damage dealt into true damage and increases their ATK by{' '}
                <b>10%</b> for the rest of the battle.
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="reinier"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="hugin"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="ulmus"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="niru"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #11</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Increases Phys DEF and Magic DEF by <b>5</b> for the caster and
                allies within <b>2</b> tiles every <b>30%s</b> for <b>5s</b>.
                This effect can't stack. Gains a shield of <b>20%</b> for{' '}
                <b>5s</b>.
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="callan"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="antandra"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #12</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Becomes Invincible and Unaffected for <b>5s</b> when HP drops
                below <b>30%</b> for the first time, restoring <b>50</b> Energy
                per second during this period.
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="mikola"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="hewynn"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="dionel"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #13</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Gains <b>30</b> Crit. When casting Ultimate, gains <b>40</b>{' '}
                Crit DMG Boost for <b>8s</b> and recovers HP equal to <b>40%</b>{' '}
                of Ultimate damage
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="lenya"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="scarlita"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="rhys"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #14</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Increases the ATK of another ally by <b>15%</b> for <b>6s</b>{' '}
                when healing or granting a shield to them. This effect does not
                stack. If the ally's HP is higher than <b>80%</b>, grants an
                additional <b>20</b> Haste.
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="elijah-and-lailah"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="ludovic"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="lucius"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="korin"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="fay"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #15</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Deals extra damage every <b>3</b> normal attack hits equal to
                the hit target's <b>8%</b>, up to the caster's <b>350%</b>.
                Grants a <b>80</b> ATK SPD bonus for <b>6s</b> every time the
                caster uses their Ultimate.
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="odie"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="bryon"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="sinbad"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="marilee"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #16</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Restores <b>80</b> Energy per second until the caster's Energy
                is fully restored for the first time. Upon casting the first
                Ultimate, increases ATK by <b>30%</b> and Crit by <b>30</b> for{' '}
                <b>8s</b>.
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="dunlingr"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="faramor"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="viperian"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="rowan"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="brutus"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="valen"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #17</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                Taunts enemies within a <b>2-tile</b> radius for <b>5s</b> and
                gains a <b>320%</b> shield after each Ultimate cast.
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="phraesto"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="column">
            <h5>Charm #18</h5>
            <div className="artifact-description">
              <p className="sub">Set Bonus:</p>
              <p>
                When dealing damage, strikes the target enemy again for{' '}
                <b>150%</b> damage and grants the caster an extra <b>10%</b> ATK
                for <b>8s</b>, stackable up to a max increase of <b>40%</b> ATK.
                This effect can be triggered once every <b>6s</b> per enemy and
                also applies to the caster's summons. Each time this effect is
                triggered, restores HP equal to <b>30%</b> of the damage dealt
                by the extra attack.
              </p>
            </div>
            <div className="artifact-review">
              <p className="sub">Characters using this Charm:</p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="cryonaia"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="bonnie"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="hodgkin"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="lyca"
                  showLabel
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="florabelle"
                  showLabel
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesCharms;

export const Head: React.FC = () => (
  <Seo
    title="Charms - Season 3 | AFK Journey | Prydwen Institute"
    description="List of Charms available in the Season 3 of AFK Journey."
    game="afk"
  />
);
